import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import logo from './header.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';

function Header() {
    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <header>
            <Navbar bg="light" expand="lg" className="navbar">
                <Container>
                    {/* Branding */}
                    <LinkContainer to="/">
                        <Navbar.Brand>
                            <img
                                src={logo}
                                width="60"
                                height="auto"
                                className="d-inline-block align-top"
                                alt="Shawn Dev's Logo"
                            />
                        </Navbar.Brand>
                    </LinkContainer>

                    {/* Hamburger menu */}
                    <Navbar.Toggle className='hamburger-menu' aria-controls="basic-navbar-nav" />

                    {/* Navbar links */}
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <LinkContainer to="/" exact>
                                <Nav.Link className={currentPath === '/' ? 'active-nav-link' : ''}>Home</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/Templates">
                                <Nav.Link className={currentPath === '/Templates' ? 'active-nav-link' : ''}>Templates</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/FAQ">
                                <Nav.Link className={currentPath === '/FAQ' ? 'active-nav-link' : ''}>FAQ</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/Contact">
                                <Nav.Link className={currentPath === '/Contact' ? 'active-nav-link' : ''}>Contact</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/About">
                                <Nav.Link className={currentPath === '/About' ? 'active-nav-link' : ''}>About</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/Prices-and-Packages">
                                <Nav.Link className={currentPath === '/Prices-and-Packages' ? 'active-nav-link' : ''}>Prices & Packages</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
