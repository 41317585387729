import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

// Styled components
const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  color: #333;
  margin-bottom: 16px;
  text-align: center;
`;

const Text = styled.p`
  color: #666;
  line-height: 1.6;
  font-size: 16px;
`;

function CookiePolicy() {
  return (
    <Container>

            {/* SEO */}
            <Helmet>
                <title>Shawn Dev - Cookie Policy</title>
                <meta name="description" content="Learn about Shawn Dev journey in the web design industry. Discover how i use React and React Native to create cutting-edge website and app templates. my commitment to quality and innovation sets me apart from other website agency's you will find." />
                <meta name="keywords" content="about Shawn Dev, web design, web development, React templates, React Native, innovative web solutions, website templates, app development, digital services" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/CookiePolicy" />
            </Helmet> 

      <Header>Cookie Policy</Header>
      <Text>
      <Text>Cookie Policy  </Text>

      <Text>1. Introduction
   Shawndevs uses cookies to improve your experience on my site. By using my site, you consent to my use of cookies.  </Text>

   <Text>2. What are Cookies
   Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored in your web browser and allows us or a third-party to recognize you and make your next visit easier and the service more useful to you.  </Text>

   <Text>3. How We Use Cookies
   We use cookies to enable certain functions of the service, to provide analytics, to store your preferences, and to enable advertisements delivery.  </Text>

   <Text>4. Your Choices Regarding Cookies
   If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.  </Text>

      </Text>
    </Container>
  );
}

export default CookiePolicy;

