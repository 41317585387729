import React from "react";
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';

function SEOServices() {
    return (
        <Container className="my-5">
            {/* SEO */}
            <Helmet>
                <title>Shawn Dev - SEO Services</title>
                <meta name="description" content="Discover how Shawn Dev innovative SEO services can boost your website's visibility and online presence. Our team of experts uses the latest strategies to improve rankings and drive organic traffic." />
                <meta name="keywords" content="Shawn Dev, SEO services, website visibility, online presence, SEO strategy, keyword research, on-page optimization, link building, content creation, analytics, user experience" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/SEOServices" />
            </Helmet>

            <h1 className="text-center">SEO Services</h1>

            <p className="text-center lead">
                Elevate your website's visibility with our cutting-edge SEO services. I specialize in improving search engine rankings to drive traffic and enhance your online presence.
            </p>

            <Row className="mb-4">
                <Col>
                    <h2 className="text-center">Our SEO Strategy</h2>
                    <p>
                        I employ a comprehensive, multi-faceted approach to SEO that includes advanced keyword research, on-page optimization, strategic link building, and content strategy. My methods stay ahead of the latest SEO trends and algorithms.
                    </p>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <h2 className="text-center">Keyword Research & Optimization</h2>
                    <p>
                        Harness the power of targeted keywords. I in-depth research identifies the most effective keywords for your business, seamlessly integrating them into your website's content.
                    </p>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <h2 className="text-center">On-Page SEO</h2>
                    <p>
                        Boost your site's performance. I focus on optimizing all on-page elements, including meta tags, headings, images, and internal link structure, to ensure optimal search engine visibility.
                    </p>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <h2 className="text-center">Link Building</h2>
                    <p>
                        Build a strong backlink profile. My strategies acquire high-quality backlinks that enhance your site's authority and ranking.
                    </p>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <h2 className="text-center">Content Creation & Marketing</h2>
                    <p>
                        Content reigns supreme in SEO. We create engaging, Informative content that resonates with your audience and drives organic traffic.
                    </p>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <h2 className="text-center">Analytics and Reporting</h2>
                    <p>
                        Stay informed with My comprehensive analytics and reporting. I provide regular updates on your SEO campaign's performance, including traffic, rankings, and conversions.
                    </p>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <h2 className="text-center">Why Choose My SEO Services?</h2>
                    <p>
                        My dedicated team of SEO experts is committed to delivering tangible results. I focus on not just increasing rankings but also enhancing the overall user experience and conversion rates on your site.
                    </p>
                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <p>
                        Ready to boost your website’s SEO? Contact me today for a detailed consultation.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default SEOServices;

