import React from "react";
import { Helmet } from 'react-helmet';
import { Container, ListGroup } from 'react-bootstrap';

function WebsiteDevelopment() {
    return (
        <Container className="my-5">
            {/* SEO */}
            <Helmet>
                <title>Shawn Dev - Website Development</title>
                <meta name="description" content="Discover how Shawn Dev creates high-performance, scalable, and secure websites using the latest technologies. We focus on custom solutions that meet your business objectives." />
                <meta name="keywords" content="Shawn Dev, web development, React.js, Node.js, SQL, AWS, website design, responsive design, SEO optimization, performance tuning" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/WebsiteDevelopment" />
            </Helmet>

            <h1 className="text-center">Website Development</h1>
            <p className="lead text-center">
                We create high-performance, scalable, and secure websites using the latest technologies to meet your business objectives.
            </p>

            <h2 className="mt-4 text-center">Technologies We Use</h2>
            <ListGroup className="mb-4">
                <ListGroup.Item className="bg-light mb-2 p-3 border-start border-5 border-primary">
                    <strong>Frontend:</strong> HTML5, CSS3, JavaScript, React.js
                </ListGroup.Item>
                <ListGroup.Item className="bg-light mb-2 p-3 border-start border-5 border-primary">
                    <strong>Backend:</strong> Node.js, Python, PHP
                </ListGroup.Item>
                <ListGroup.Item className="bg-light mb-2 p-3 border-start border-5 border-primary">
                    <strong>Database Management:</strong> SQL, PostgreSQL, MongoDB
                </ListGroup.Item>
                <ListGroup.Item className="bg-light mb-2 p-3 border-start border-5 border-primary">
                    <strong>Cloud and Hosting:</strong> AWS, Google Cloud, Heroku, and Fasthosts
                </ListGroup.Item>
            </ListGroup>

            <h2 className="mt-4 text-center">My Development Process</h2>
            <p>
                My process is transparent and iterative. It begins with a detailed analysis of your requirements, followed by designing a prototype. Once approved, the development phase begins, ensuring constant communication and updates. The final step involves rigorous testing and quality assurance before launch.
            </p>

            <h2 className="mt-4 text-center">Responsive and Adaptive Design</h2>
            <p>
                I ensure that all websites developed are not just visually appealing but also fully responsive and adaptive to different devices and screen sizes, providing an optimal user experience.
            </p>

            <h2 className="mt-4 text-center">SEO and Performance Optimization</h2>
            <p>
                My development includes SEO optimization and performance tuning to ensure high visibility and fast loading times.
            </p>

            <h2 className="mt-4 text-center">Why Choose Our Services?</h2>
            <p>
                I am committed to delivering custom, cutting-edge solutions tailored to your specific needs, with a focus on scalability, security, and user engagement.
            </p>

            <h2 className="mt-4 text-center">Get a Quote</h2>
            <p className="text-center">
                Ready to start your website development project? Contact me for a consultation and quote.
            </p>
        </Container>
    );
}

export default WebsiteDevelopment;
