import React from 'react';

const GoogleTagManager = () => {
  return (
    <>
      {/* Google Tag Manager Script */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-2F509QR1DG"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-2F509QR1DG');
        `}
      </script>
    </>
  );
};

export default GoogleTagManager;
