import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './FAQ.css';

function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className="faq-item" onClick={toggle}>
            <h3>{question}</h3>
            {isOpen && <p className="faq-answer">{answer}</p>}
        </div>
    );
}

function FAQ() {
    return (
        <div className="container my-5 faq-page">
            <Helmet>
                <title>Shawn Dev - Frequently Asked Questions</title>
                <meta name="description" content="Find answers to all your questions about our services and products on the Shawn Dev's FAQ page. From basic queries to detailed information, we cover everything to help you understand our offerings better." />
                <meta name="keywords" content="frequently asked questions, FAQs, shawn dev help, product questions, service inquiries, customer support, user guidance" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/FAQ" />
            </Helmet>
            <h2 className="text-center mb-5">Frequently Asked Questions</h2>
            <FAQItem
                question="Q: How do i get in contact with you ?"
                answer="A: You can get in contact with me on this email admin@shawndev.me"
            />
            <FAQItem
                question="Q: Is all the content on the sites just placeholder?"
                answer="A: Yes everything on site is just placeholder and can be taken off and your content on."
            />
            <FAQItem
                question="Q: What services does your web agency offer?"
                answer="A: I offer a range of services, including template sales, ongoing support, and full-service freelance web development. You can choose the option that best fits your needs."
            />
            <FAQItem
                question="Q: Can I customize the templates to match my branding?"
                answer="A: Yes, my templates are fully customizable. You can personalize them with your brand colors, logo, and content."
            />
            <FAQItem
                question="Q: How can I contact your agency for inquiries or assistance?"
                answer="A: You can reach out to me through my contact form, email, or phone. I'm here to help and answer any questions you may have."
            />
            <FAQItem
                question="Q: Do you offer ongoing website maintenance and support?"
                answer="A: Yes, I provide maintenance packages to ensure your website runs smoothly. My support includes updates, security checks, and technical assistance."
            />
            <FAQItem
                question="Q: What is the turnaround time for template customization?"
                answer="A: The turnaround time for template customization depends on the complexity of the changes you require. I aim to complete most customizations within a day, but i will provide you with a more accurate estimate after reviewing your request."
            />

            <FAQItem
                question="Q: How do I purchase a custom template design?"
                answer="A: To purchase a custom template design, Go to Templates page you can view or buy click on buy fill in payment details and the buy button will change to download after payment is successful."
            />

            <FAQItem
                question="Q: Are your templates SEO-friendly?"
                answer="A: Yes, my templates are designed with SEO best practices in mind. They include clean and semantically structured HTML, which can help improve your website's search engine rankings. Additionally, you can further enhance SEO by optimizing your content and meta tags."
            />

            <FAQItem
                question="Q: Can I request additional features or functionality for my website?"
                answer="A: Certainly! If you have specific features or functionality in mind that are not included in our templates, please let us know. We offer custom web development services and can implement the features you need to meet your website's requirements."
            />

            <FAQItem
                question="Q: What payment methods do you accept for web development projects?"
                answer="A: I accept various payment methods for web development projects, including major credit cards and bank transfers. I will provide you with payment instructions once the project details are confirmed."
            />

            <FAQItem
                question="Q: What is the cost of template customization?"
                answer="A: Template customization costs can vary depending on the extent of changes required. I provide personalized quotes based on your specific customization needs."
            />

            <FAQItem
                question="Q: Do you offer website maintenance packages?"
                answer="A: Yes, i offer website maintenance packages to ensure your site remains up-to-date, secure, and error-free. My packages include regular updates, security checks, and technical support."
            />

            <FAQItem
                question="Q: How long does it take to complete a full-service web development project?"
                answer="A: The timeline for a full-service web development project depends on its complexity and features. I provide estimated project durations during our initial consultation."
            />

            <FAQItem
                question="Q: Are your websites mobile-responsive?"
                answer="A: Yes, all my websites are designed to be mobile-responsive, ensuring they look and function well on various devices, including smartphones and tablets."
            />

            <FAQItem
                question="Q: Do you provide SEO services to improve website visibility?"
                answer="A: Yes, i offer SEO optimization services to help improve your website's search engine rankings. My services include keyword research, on-page SEO, and content optimization."
            />

            <FAQItem
                question="Q: Can I request ongoing website updates and changes after the project is completed?"
                answer="A: Certainly! i offer post-launch support and can assist with making updates, adding new features, and addressing any issues that may arise."
            />

        </div>
    );
}

export default FAQ;
