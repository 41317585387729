import React, { useState, useEffect, useMemo } from 'react';
import styles from './Banner.module.css';

function Banner() {
    // Memoize the texts array so it doesn't get redefined on every render
    const texts = useMemo(() => [
        "Contact for a quote on the website you want that i will create from scratch!", 
        "Check out our latest designs!", 
        "Special offers available now!",
    ], []); // Empty dependency array means it only runs once

    const [currentText, setCurrentText] = useState(texts[0]);
    const [show, setShow] = useState(true);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % texts.length);
            setCurrentText(texts[index]);
        }, 10000);

        return () => clearInterval(interval);
    }, [index, texts]); 

    if (!show) return null;

    return (
        <div className={styles.shellBanner}>
            {currentText}
            <span className={styles.closeShell} onClick={() => setShow(false)}></span>
        </div>
    );
}

export default Banner;
