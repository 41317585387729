import React from 'react';
import styles from './ServicesSection.module.css';

function ServicesSection() {
    const services = [
        { icon: '⚙️', title: 'Maintenance', description: 'Regular updates and maintenance for your web applications.' },
        { icon: '📱', title: 'Mobile App Development', description: 'We develop mobile applications for both iOS and Android platforms.' },
        { icon: '📊', title: 'Digital Marketing', description: 'Boost your online presence and drive more traffic with our marketing strategies.' },
        { icon: '🌐', title: 'Hosting & Domain Services', description: 'Powered by FastHost, we provide reliable hosting solutions with domain registration and management services.' },
        { icon: '🔗', title: 'Backend API Development', description: 'Designing, building, and maintaining robust and scalable APIs to power your web applications.' },
        { icon: '🔍', title: 'SEO Optimization', description: 'Strategies to improve your website\'s visibility in search engine results.' },
        { icon: '🎨', title: 'Template Customization', description: 'Select from our wide range of templates, and we\'ll customize it to fit your needs. It\'s a quick and cost-effective solution for a tailored web presence.' },
        { icon: '🖥️', title: 'Full Design & Development', description: 'Want something unique? We provide a comprehensive service from design to deployment, crafting a website that\'s uniquely yours.' }
    ];

    return (
        <div className={styles.servicesSection}>
            <div className={styles.servicesTitle}>My Services</div>

            {services.map((service, index) => (
                <div key={index} className={styles.service}>
                    <div className={styles.serviceIcon}>{service.icon}</div>
                    <div className={styles.serviceTitle}>{service.title}</div>
                    <p>{service.description}</p>
                </div>
            ))}
        </div>
    );
}

export default ServicesSection;
