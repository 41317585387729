import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Contact.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Logo from './header1.png';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        submitted: false 
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('https://shawn-devs-backend.onrender.com/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: formData.name,
                email: formData.email,
                message: formData.message
            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then((data) => {
                console.log('Success:', data);

                // Clear form fields
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                    submitted: true // Update the state to indicate form submission
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="container my-5">
            <Helmet>
                <title>Shawn Dev - Contact Page</title>
                <meta name="description" content="Need assistance or have a question? Contact Shawn today. Find my phone number, email address, or use the online form to reach me directly. I'll be here to help every step of the way!" />
                <meta name="keywords" content="contact Shawn, Shawn dev contact information, customer service, support, get in touch, phone number, email address, contact form" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/Contact" />
            </Helmet>

            <div className="row">
                {formData.submitted ? (
                    <div className="col-md-12">
                        <h2>Thank You!</h2>
                        <p>Your message has been submitted successfully.</p>
                    </div>
                ) : (
                    <>
                        <div className="col-md-6">
                            <img src={Logo} alt="Shawn Dev Logo" style={{ width: '450px' }} />
                        </div>
                        <div className="col-md-6">
                            <h2>Contact Me</h2>
                            <form onSubmit={handleSubmit} className="contact-form">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name:</label>
                                    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email:</label>
                                    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label">Message:</label>
                                    <textarea className="form-control" id="message" name="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>

                            <div className="contact-details mt-4">
                        <p>Email: <a href="mailto:admin@shawndev.me">admin@shawndev.me</a></p>
                        <p>Phone: <a href="tel:+1234567890">07711260765</a></p>
                        <div className="social-icons">
                            <a target='blank' href="https://www.facebook.com/profile.php?id=100094664080836" className="text-primary me-2"><i className="fab fa-facebook-f"></i></a>
                            <a target='blank' href="https://twitter.com/shawnking630217" className="text-info me-2"><i className="fab fa-twitter"></i></a>
                            <a target='blank' href="https://www.instagram.com/shawndevs/" className="text-danger me-2"><i className="fab fa-instagram"></i></a>
                            <a target='blank' href="https://www.linkedin.com/in/shawn-cocking-9a0b8b163/" className="text-primary"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Contact;
