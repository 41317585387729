import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Logo from './Artboard 23.png'

const mailchimpUrl = "https://hotmail.us21.list-manage.com/subscribe/post?u=e04522a47d88e7c089e85e30d&amp;id=ec4b7a9227&amp;f_id=00e8e8e6f0";

function Footer () {
    return ( 
        <div className='footer-container'>
           <section className='footer-sub'>
                <p className='footer-sub-heading'>
                    Join the Newsletter to receive our best Deals and advice
                </p>
                <p className="footer-sub-text">
                    You can unsubscribe at any time.
                </p>
                <div className="input-area">
                    <form action={mailchimpUrl} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                        <input type="email" name="EMAIL" className="footer-input" placeholder='Your Email' />
                        <button className='btn-sub'>Subscribe</button>
                        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                            <input type="text" name="b_e04522a47d88e7c089e85e30d_ec4b7a9227" tabIndex="-1" value="" />
                        </div>
                    </form>
                </div>
            </section>
            <div className="footer-links">
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>Links</h2>
                        <Link to='/'>Home</Link>
                        <Link to='/Templates'>Templates</Link>
                        <Link to='/FAQ'>FAQ</Link>
                        <Link to='/Contact'>Contact</Link>
                        <Link to='/About'>About</Link>
                        <Link to='/Prices-and-Packages'>Prices & Packages</Link>
                    </div>
                    {/* <div className="footer-link-items">
                        <h2>Contact Us</h2>
                        <Link to='/'>Support</Link>
                        <Link to='/'>Contact</Link>
                        <Link to='/About'>Testimonials</Link>
                        <Link to='/'>Contact</Link>
                        <Link to='/'>Location</Link>
                    </div> */}
                    </div>
                    <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>Legal</h2>
                        <Link to='/TermsAndConditions'>Terms & Conditions</Link>
                        <Link to='/PrivacyPolicy'>Privacy Policy</Link>
                        <Link to='/CookiePolicy'>Cookie Policy</Link>
                        <Link to='/Disclaimer'>Disclaimer</Link>
                    </div>
                    <div className="footer-link-items">
                        <h2>Services</h2>
                        <Link to='/CustomDesign'>Custom Design Services</Link>
                        <Link to='/WebsiteDevelopment'>Website Development</Link>
                        <Link to='/SEOServices'>SEO Services</Link>
                        <Link to='/DigitalMarketing'>Digital Marketing</Link>
                    </div>

                </div>
            </div>
            <section className='social-media'>
                <div className="social-media-wrapper">
                    <div className="footer-logo">
                    <Link to="/">
                            <img src={Logo} alt="Shawn Dev's Logo" style={{ maxWidth: '150px' }} />
                        </Link>
                    </div>
                    <small className='website-rights'>© 2023 Shawn Dev's</small>
                    <div className="social-icons">
                        <Link to='https://twitter.com/shawnking630217' target='_blank' aria-label='twitter' className="social-icon-link">
                            <i className="fab fa-twitter"></i>
                        </Link>
                        <Link to='https://rapidapi.com/user/Shawn2208' target='_blank' aria-label='rapidapi' className="social-icon-link">
                            <i className="fab fa-rapidapi"></i>
                        </Link>
                        <Link to='https://www.linkedin.com/in/shawn-cocking-9a0b8b163/' target='_blank' aria-label='linkedin' className="social-icon-link">
                            <i className="fab fa-linkedin"></i>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer;