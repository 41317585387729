import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

// Styled components
const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  color: #333;
  margin-bottom: 16px;
  text-align: center;
`;

const Text = styled.p`
  color: #666;
  line-height: 1.6;
  font-size: 16px;
`;

function Disclaimer() {
  return (
    <Container>

            {/* SEO */}
            <Helmet>
                <title>Shawn Dev - Disclaimer</title>
                <meta name="description" content="Learn about Shawn Dev journey in the web design industry. Discover how i use React and React Native to create cutting-edge website and app templates. my commitment to quality and innovation sets me apart from other website agency's you will find." />
                <meta name="keywords" content="about Shawn Dev, web design, web development, React templates, React Native, innovative web solutions, website templates, app development, digital services" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/Disclaimer" />
            </Helmet>

      <Header>Disclaimer</Header>
      <Text>
      <Text>Disclaimer</Text>

      <Text>The information provided by Shawn Dev (“we,” “us” or “our”) on shawndev.me is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</Text>

      <Text>Shawn Dev will not be liable for any errors or omissions in this information nor for the availability of this information. Shawn Dev's will not be liable for any losses, injuries, or damages from the display or use of this information.</Text>


      </Text>
    </Container>
  );
}

export default Disclaimer;
