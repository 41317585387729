import React from 'react';
import styles from './Showcase.module.css';

function Showcase () {
    return (
        <section className={styles.showcase}>
            <div className={styles["showcase-img"]}>
                <div className={styles["overlay-content"]}>
                    <p>
                        <span className={styles.left}>Discover More</span> 
                        <span className={styles.right}> &nbsp; About Our Designs</span>
                    </p>
                    <a href="/Templates" className={styles["overlay-btn"]}>Explore Now</a>
                </div>
            </div>
            
            <div className={styles["showcase-content"]}>
                <h1>Unleash Your Web Potential</h1>
                <p>Discover unique and professional templates to boost your freelance projects.</p>
                <a href="/Templates" className={styles.cta}>Browse Templates</a>
            </div>
        </section>
    )
}

export default Showcase;
