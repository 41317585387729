import React from "react";
import { Helmet } from 'react-helmet';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';

function DigitalMarketing() {
  return (
    <Container className="py-5">
    {/* SEO */}
    <Helmet>
        <title>Shawn Dev - Digital Marketing</title>
        <meta name="description" content="Learn about Shawn Dev journey in the web design industry. Discover how I use React and React Native to create cutting-edge website and app templates. My commitment to quality and innovation sets me apart from other website agencies you will find." />
        <meta name="keywords" content="about Shawn Dev, web design, web development, React templates, React Native, innovative web solutions, website templates, app development, digital services" />
        <meta name="author" content="Shawn Cocking" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.shawndev.me/DigitalMarketing" />
    </Helmet>

    <h1 className="text-center">Digital Marketing</h1>
    <p className="text-center lead">
        Elevate your brand with my digital marketing services. We provide a range of solutions from social media marketing to email campaigns, all designed to engage your audience and grow your business.
    </p>

    <Row>
        <Col lg={6}>
            <h2>My Services</h2>
            <ListGroup>
                <ListGroup.Item>
                    <strong>Social Media Marketing:</strong> Build a strong social presence. I create custom content and campaigns that resonate with your audience on platforms like Facebook, Instagram, Twitter, and LinkedIn.
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Email Marketing:</strong> Connect directly with your customers through tailored email campaigns. I focus on high engagement rates and converting leads into customers.
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Content Marketing:</strong> Engage your audience with high-quality, relevant content. My services include blog posts, infographics, and video content creation.
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>SEO & SEM:</strong> Enhance your online visibility. I optimize your website for search engines and manage PPC campaigns to drive targeted traffic.
                </ListGroup.Item>
            </ListGroup>
        </Col>
        <Col lg={6}>
            <h2>My Approach</h2>
            <p>
                I begin with an in-depth analysis of your business, target market, and competitors. I then craft a customized digital marketing strategy that aligns with your business objectives and budget.
            </p>

            <h2>Analytics and Reporting</h2>
            <p>
                Data drives My decisions. I provide detailed analytics and regular reports on campaign performance, ensuring transparency and continuous improvement.
            </p>

            <h2>Why Choose Me?</h2>
            <p>
                I combine creativity with data-driven strategies to deliver measurable results. I take pride in being adaptive, proactive, and fully committed to your success.
            </p>

            <h2>Get Started</h2>
            <p>
                Ready to amplify your digital presence? Contact me today for a consultation and let me help you achieve your digital marketing goals.
            </p>
        </Col>
    </Row>
</Container>

  )
}

export default DigitalMarketing;
