import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Banner from './components/Banner';
import Showcase from './components/Showcase';
import ServicesSection from './components/ServicesSection';
import ProcessSection from './components/ProcessSection';
import FeaturedTemplates from './components/FeaturedTemplates';
import Footer from './components/Footer/Footer';
import GoogleTagManager from './components/GoogleTagManager/GoogleTagManager'; 


// Footer Links (LEGAL)
import CookiePolicy from './pages/Footer-Links/Legal/CookiePolicy';
import Disclaimer from './pages/Footer-Links/Legal/Disclaimer';
import PrivacyPolicy from './pages/Footer-Links/Legal/PrivacyPolicy';
import TermsAndConditions from './pages/Footer-Links/Legal/TermsAndConditions';

// Footer Links (Services)
import CustomDesign from './pages/Footer-Links/Services/CustomDesign';
import SEOServices from './pages/Footer-Links/Services/SEOServices';
import WebsiteDevelopment from './pages/Footer-Links/Services/WebsiteDevelopment';
import DigitalMarketing from './pages/Footer-Links/Services/DigitalMarketing';

import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import FAQ from './pages/FAQ/FAQ';
import Templates from './pages/Templates/Templates';
import PackagesAndPrices from './pages/PackagesAndPrices/PackagesAndPrices';
import { Helmet } from 'react-helmet';





function App() {
    return (
        <Router>
            <div>
                    <GoogleTagManager />
                    <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="index, follow" />

                {/* Social Media Meta Tags */}
                <meta property="og:title" content="Shawn Dev - Web Solutions | Website Design Cornwall" />
                <meta property="og:description" content="Based in Truro, Cornwall, Shawn dev custom web development services, digital marketing solutions, and bespoke website templates. Tailored to meet your digital needs, ensuring a standout online presence." />
                <meta property="og:image" content="https://www.shawndev.me/header.png" />
                <meta property="og:url" content="https://www.shawndev.me" />
                <meta property="og:type" content="website" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="https://www.shawndev.me/header.png" />
                <meta name="twitter:title" content="Shawn Dev - Web Solutions | Website Design Cornwall" />
                <meta name="twitter:description" content="Based in Truro, Cornwall, Shawn dev custom web development services, digital marketing solutions, and bespoke website templates." />
                <meta name="twitter:image" content="https://www.shawndev.me/header.png" />

                <meta name="author" content="Shawn Cocking" />

                <title>Shawn Dev - Home Page</title>
                <meta name="description" content="Discover Shawn Dev range of website templates - Based in Truro, Cornwall, Shawn dev custom web development services, and digital marketing solutions. Elevate your online presence with user-friendly interfaces and affordable templates for all types of websites." />
                <meta name="keywords" content="Web Design Cornwall, cornwall, website templates, web development, digital marketing, custom design, responsive web design, Shawn Dev, online marketing, SEO services" />
                    </Helmet>

                <Header />
                <MainContent />
                <Footer />
            </div>
        </Router>
    );
}

function MainContent() {
    let location = useLocation();

    if (location.pathname === '/Templates') {

        return <Templates />;

    } else if (location.pathname === '/About') {

        return <About />;

    } else if (location.pathname === '/FAQ') {

        return <FAQ />;

    } else if (location.pathname === '/Contact') {
        
        return <Contact />;

    } else if (location.pathname === '/CookiePolicy') {
        return <CookiePolicy />;

    } else if (location.pathname === '/Disclaimer') {
        return <Disclaimer />;
    } else if (location.pathname === '/PrivacyPolicy') {
        return <PrivacyPolicy />;
    } else if (location.pathname === '/TermsAndConditions') {
        return <TermsAndConditions />;

    } else if (location.pathname === '/DigitalMarketing') {
        return <DigitalMarketing />;

    } else if (location.pathname === '/SEOServices') {
        return <SEOServices />;

    } else if (location.pathname === '/Prices-and-Packages') {
        return <PackagesAndPrices />;

    } else if (location.pathname === '/WebsiteDevelopment') {
        return <WebsiteDevelopment />;

    } else if (location.pathname === '/CustomDesign') {
        return <CustomDesign />;
    }
 
    return (
        <>
            <Routes>
                <Route path='/TermsAndConditions' element={<TermsAndConditions />} />
                <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
                <Route path='/Disclaimer' element={<Disclaimer />} />
                <Route path='/CookiePolicy' element={<CookiePolicy />} />
                <Route path="/" element={<Banner />} />
                <Route path="/About" element={<About />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/FAQ" element={<FAQ />} />
                <Route path="/Templates" element={<Templates />} />
                <Route path='/DigitalMarketing' element={<DigitalMarketing />} />
                <Route path='/SEOServices' element={<SEOServices />} />
                <Route path='/WebsiteDevelopment' element={<WebsiteDevelopment /> } />
                <Route path='/CustomDesign' element={<CustomDesign />} />
                <Route path='/Prices-and-Packages' element={<PackagesAndPrices />} />
            </Routes>
            <Showcase />
            <ServicesSection />
            <ProcessSection />
            <FeaturedTemplates />
        </>
    );
}

export default App;
