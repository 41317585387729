import React from 'react';
import { Container, Row, Col, Card, Button, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './PackageAndPrices.module.css';
import { Helmet } from 'react-helmet';

const packages = [
    {
        title: 'Basic Package',
        price: '£299 - £599',
        features: [
            'Custom-designed 5-page website example webpages (Home, Services, Gallery, About, Contact)',
            'Basic SEO setup',
            '3 Round Revisions',
            'Mobile responsive design',
            'Social media integration',
            'Basic security features',
            ''
        ],
        maintenance: '£25/month - Basic maintenance and content updates'
    },
    {
        title: 'Standard Package',
        price: '£600 - £1,200',
        features: [
            'Custom-designed 15-page website',
            'Intermediate SEO optimization',
            'Basic e-commerce functionality (if needed)',
            'Mobile responsive design',
            'Enhanced security features',
            'Basic analytics integration',
            '5 Round Revisions',
        ],
        maintenance: '£50/month - Standard maintenance, content updates, and SEO monitoring'
    },
    {
        title: 'Premium Package',
        price: '£1,200 - £2,500+',
        features: [
            'Custom-designed website with unlimited pages',
            'Advanced SEO optimization',
            'Mobile responsive and optimized design',
            'Advanced security features',
            'Comprehensive analytics and performance reports',
            '10 rounds of revisions',
            'Initial digital marketing and social media strategy consultation'
        ],
        maintenance: '£100/month - Premium maintenance, technical support, performance optimization, and detailed SEO and analytics reporting'
    },
    {
        title: 'Web Application Development Package',
        price: 'Custom quoted based on requirements',
        features: [
            'Custom web application design and development',
            'Requirement analysis and planning',
            'Database design and integration',
            'Testing and quality assurance',
            'Deployment and ongoing maintenance',
            'Integration with third-party services (CRM, ERP, etc.)',
            'Scalable and secure infrastructure'
        ],
        maintenance: 'Custom - Based on the complexity and requirements of the application'
    },
    {
        title: 'Website Redesign Package',
        price: '£500 - £1,000+',
        features: [
            'Complete website audit and redesign',
            'Visual elements and UI/UX enhancement',
            'Content update and optimization',
            'SEO optimization and analytics integration',
            'Mobile-responsive and optimized design',
            'Conversion rate optimization',
            'User testing and feedback implementation'
        ],
        maintenance: '£50/month - Includes regular updates, security monitoring, and performance optimization'
    },
    {
        title: 'Maintenance and Support Package',
        price: 'Custom - Based on the level of support required',
        features: [
            'Regular website updates and backups',
            'Security monitoring and updates',
            'Technical support and troubleshooting',
            'Content updates and SEO maintenance',
            'Performance monitoring and optimization',
            'Regular reports and analytics'
        ],
        maintenance: 'Custom to the specific needs and the complexity of the website.'
    },
];



function PackagesAndPrices() {
    return (
        <Container className="my-5">

            <Helmet>
                <title>Shawn Dev - Prices & Packages</title>
                <meta name="description" content="Welcome to Shawn Dev Prices and Packages this is where you find wide range of packages" />
                <meta name="keywords" content="web design packages, web development packages" />
                <meta name="author" content="Shawn Cocking" />
                <link rel="canonical" href="https://www.shawndev.me/Prices-and-Packages" />
                <meta property="og:title" content="Shawn Dev - Prices & Packages" />
                <meta property="og:description" content="Your page description here." />
                <meta property="og:image" content="https://www.shawndev.me/header.png" />
                <meta property="og:url" content="https://www.shawndev.me/Prices-and-Packages" />
                <meta name="twitter:card" content="https://www.shawndev.me/header.png" />
                <meta name="twitter:title" content="Shawn Dev - Prices & Packages" />
                <meta name="twitter:description" content="Your page description here." />
                <meta name="twitter:image" content="https://www.shawndev.me/header.png" />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Language" content="en" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Row className="mb-3">
                <Col>
                    <h1 className="text-center">My Packages & Prices</h1>
                </Col>
            </Row>
            <Row>
                {packages.map(packages => (
                    <Col key={packages.title} md={4} className="mb-4">
                        <Card className="h-100">
                            <Card.Header as="h2" className="text-center">{packages.title}</Card.Header>
                            <Card.Body>
                                <Card.Title className="text-center">{packages.price}</Card.Title>
                                <ListGroup variant="flush">
                                    {packages.features.map(feature => (
                                        <ListGroup.Item key={feature}>{feature}</ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer className="text-muted">
                                <p>{packages.maintenance}</p>
                                <Button variant="primary" href="/Contact" className={styles.customEnquireButton} exact="true" block="true">Enquire</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}
export default PackagesAndPrices;
