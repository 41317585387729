import React, { useState } from 'react';
import './TestimonialsCarousel.css'; // CSS file for styling

const testimonials = [
    {
        id: 1,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec diam orci. Proin eget nisl et erat mollis consectetur.",
        author: "John Doe"
    },
    {
        id: 2,
        text: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent blandit bibendum dolor.",
        author: "Jane Smith"
    },
    {
        id: 3,
        text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.",
        author: "Alex Johnson"
    }
    
];

function TestimonialsCarousel() {
    const [current, setCurrent] = useState(0);
    const length = testimonials.length;

    const nextTestimonial = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevTestimonial = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(testimonials) || testimonials.length <= 0) {
        return null;
    }

    return (
        <div className="carousel-container">
            <div className="carousel-slider">
                {testimonials.map((testimonial, index) => (
                    <div key={testimonial.id} className={index === current ? 'slide active' : 'slide'}>
                        {index === current && (
                            <div>
                                <p className="testimonial-text">{testimonial.text}</p>
                                <p className="author">- {testimonial.author}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="carousel-buttons">
                <button className="prev" onClick={prevTestimonial}>Prev</button>
                <button className="next" onClick={nextTestimonial}>Next</button>
            </div>
        </div>
    );
}

export default TestimonialsCarousel;
