import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 800px; // Sets a max-width for readability
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  color: #333;
  margin-bottom: 16px;
  text-align: center;
`;

const Text = styled.p`
  color: #666;
  line-height: 1.6;
  font-size: 16px;
`;

function TermsAndConditions() {
  return (
    <Container>

            {/* SEO */}
            <Helmet>
                <title>Shawn Dev - Terms And Conditions</title>
                <meta name="description" content="Learn about Shawn Dev journey in the web design industry. Discover how i use React and React Native to create cutting-edge website and app templates. my commitment to quality and innovation sets me apart from other website agency's you will find." />
                <meta name="keywords" content="about Shawn Dev, web design, web development, React templates, React Native, innovative web solutions, website templates, app development, digital services" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/TermsAndConditions" />
            </Helmet>

      <Header>Terms & Conditions</Header>
      <Text>1. Introduction Welcome to Shawn Dev. By accessing my website and services, you agree to these terms and conditions. </Text>

      <Text>2. Intellectual Property Rights
      My website's content, including design, graphics, and text, is protected by intellectual property laws. Reproduction or distribution without permission is prohibited.</Text>

      <Text>3. Acceptable Use
      Use My website lawfully and without infringing on others' rights or limiting their access and enjoyment.</Text>

      <Text>4. Limitation of Liability
      My website and services are provided "as is." We do not guarantee uninterrupted access or error-free service.</Text>

      <Text>5. Governing Law
      These terms are governed by the laws of the United Kingdom.</Text>


     
    </Container>
  );
}

export default TermsAndConditions;
