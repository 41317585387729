import React from 'react';
import './ProcessSection.module.css'

function ProcessSection () {
    return (
        <section className="py-5 our-process-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Detailed Process</h2>
              <p>Follow the comprehensive guide from selection to launch. Here's what you can expect at each stage:</p>
            </div>
          </div>
    
          <div className="row mt-4">
            <div className="col-md-4 text-center">
              <i className="fas fa-laptop-code fa-2x mb-3"></i>
              <h5>Choose Your Template</h5>
              <p>
                Explore the extensive gallery of professionally designed website templates suitable for a variety of niches. 
                Take your time to browse and preview the templates to find the one that aligns with your vision or business needs. 
                This stage typically takes an hour.
              </p>
            </div>
    
            <div className="col-md-4 text-center">
              <i className="fas fa-shopping-cart fa-2x mb-3"></i>
              <h5>Purchase & Download</h5>
              <p>
                After selecting your template, proceed to purchase. You'll be redirected to a page to download the template and its documentation. 
                Ensure you have the necessary software to open the files. 
                If you encounter any issues during this stage, contact me.
              </p>
            </div>
    
            <div className="col-md-4 text-center">
              <i className="fas fa-hands-helping fa-2x mb-3"></i>
              <h5>Need Assistance?</h5>
              <p>
                If you're facing any challenges or need specific modifications, reach out to me. 
                Provide details of the issue or modification needed, and i will get back to you within 24 hours.
              </p>
            </div>
          </div>
    
          <div className="row mt-4">
            <div className="col-md-4 text-center">
              <i className="fas fa-users fa-2x mb-3"></i>
              <h5>Collaborative Customization</h5>
              <p>
                Opt for a collaborative session if you prefer a hands-on approach. 
                Schedule a time with me, and we'll work together on customizing the template. 
                This is a great opportunity to learn and ensure the template fits your unique needs.
              </p>
            </div>
    
            <div className="col-md-4 text-center">
              <i className="fas fa-rocket fa-2x mb-3"></i>
              <h5>Finalization & Launch</h5>
              <p>
                When you're satisfied with the customization, it's time to launch. 
                I'll guide you through final checks and ensure everything is optimized. 
                This stage ensures that your site is ready for public viewing and interaction.
              </p>
            </div>
    
            <div className="col-md-4 text-center">
              <i className="fas fa-tools fa-2x mb-3"></i>
              <h5>Ongoing Support</h5>
              <p>
                Support continues even after your site goes live. 
                Choose from the support packages for assistance with updates, maintenance, or any issues you may encounter. 
                I'm here to ensure your website runs smoothly.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default ProcessSection; 