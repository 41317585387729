import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Helmet } from 'react-helmet';
import Garden from '../../components/FeaturedTemplates/beautiful-gardens.png';
import template from '../../components/FeaturedTemplates/templates.png';
import freshsuit from '../../components/FeaturedTemplates/Freshsuits.png';
import techshop from '../../components/FeaturedTemplates/tech-shop.png';



// All the templates with name, price, image, and a key relating back to Amazon Web Services 
function Templates() {
    
    const [purchasedTemplates, setPurchasedTemplates] = useState({});
    const [buyingTemplate, setBuyingTemplate] = useState(null);

    const templates = [
        { name: 'Experiences site', price: 35.00, image: template, key: 'Experiences Site.zip', previewUrl: '    https://experience-site.netlify.app'},
        { name: 'Freshsuits', price: 70.00, image: freshsuit, key: 'fresh-suits.zip', previewUrl: 'https://preview-freshsuits.netlify.app'},
        { name: 'Beautiful Gardens', price: 50.00, image: Garden, key: 'Beautiful Gardens.zip', previewUrl: 'https://preview-landscaping.netlify.app/'},
        { name: 'Tech Shop', price: 40.00, image: techshop, key: 'tech-shop.zip', previewUrl: 'https://preview-techshop.netlify.app/'},
    ];


    const handlePaymentSuccess = (templateKey) => {
        setPurchasedTemplates(prevState => ({
            ...prevState,
            [templateKey]: true
        }));
        setBuyingTemplate(null); // Reset buying template
    };

    // Download Handling from AWS Bucket
    const handleDownload = (templateKey) => {
        fetch('https://shawn-devs-backend.onrender.com/api/get-download-url', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ key: templateKey }),
        })
        .then(response => response.json())
        .then(data => {
            // data.url contains the signed URL
            window.open(data.url, '_blank'); // Open the URL in a new tab to start the download
        })
        .catch(error => console.error('Error fetching download URL:', error));
    };


    // Render TemplateCards
     const renderTemplateCard = (template) => (
        <div className="col-md-4 mb-4" key={template.name}>
            <div className="card">
           <img style={{ height: '200px', width: 'auto' }} src={template.image} className="card-img-top" alt={template.name} />
                <div className="card-body">
                    <h5 className="card-title">{template.name}</h5>
                    <p className="card-text">£{template.price}</p>
                    <a target="blank" href={template.previewUrl} style={{ marginRight: '20px' }} className="btn btn-primary">View</a>

                    {!purchasedTemplates[template.key] && !buyingTemplate && (
                        <button onClick={() => setBuyingTemplate(template.key)} className="btn btn-warning">Buy</button>
                    )}

                    {buyingTemplate === template.key && (
                        <PayPalScriptProvider options={{ "client-id": "AYEle6VYDY7RA-0GuNqtyvEW63nOIf2bXZ6CCGoGcAbqOOo0gI26QMUpeY9nJOHUFK9fAlEa073hQZ_0" }}>
                            <br />
                            <PayPalButtons
                                style={{ layout: "vertical" }}
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [{
                                            amount: {
                                                value: template.price,
                                            },
                                        }],
                                    });
                                }}
                                onApprove={(data, actions) => {
                                    return actions.order.capture().then(() => {
                                        handlePaymentSuccess(template.key);
                                    });
                                }}
                                onError={(err) => {
                                    console.error('Payment Error:', err);
                                    setBuyingTemplate(null);
                                }}
                                onCancel={() => {
                                    setBuyingTemplate(null);
                                }}
                            />
                        </PayPalScriptProvider>
                    )}

                    {purchasedTemplates[template.key] && (
                        <button onClick={() => handleDownload(template.key)} className="btn btn-success">Download</button>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <section className="py-5">
            
            <Helmet>
                <title>Shawn Dev - Templates </title>
                <meta name="description" content="Find the perfect website template for your business or personal project with Shawn Dev. From e-commerce and portfolio designs to specialized templates for gyms, restaurants, and booking systems, we have a comprehensive selection to suit your style and needs." />
                <meta name="keywords" content="website templates, e-commerce templates, personal portfolio, business website, responsive design, professional templates, gym template, restaurant template, booking system" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/Templates" />
            </Helmet>

            <div className="container">
                <div className="row mb-4">
                    <div className="col-md-12 text-center">
                        <h2>My Templates</h2>
                        <p>Browse through my range of website templates.</p>
                    </div>
                </div>
                <div className="row">
                    {templates.map(template => renderTemplateCard(template))}
                </div>
            </div>
        </section>
    );
}

export default Templates;