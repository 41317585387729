import React from "react";
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Container, Row, Col, ListGroup } from 'react-bootstrap';

function CustomDesign() {
    return (
        <Container className="my-4">
            {/* SEO */}
            <Helmet>
                <title>Shawn Dev - Custom Design</title>
                <meta name="description" content="Learn about Shawn Dev journey in the web design industry. Discover how I use React and React Native to create cutting-edge website and app templates. My commitment to quality and innovation sets me apart from other website agencies you will find." />
                <meta name="keywords" content="about Shawn Dev, web design, web development, React templates, React Native, innovative web solutions, website templates, app development, digital services" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/CustomDesign" />
            </Helmet>

            <h1 className="text-center mb-4">Custom Design Services</h1>

            <p className="mb-3">
                I offer personalized and innovative design solutions tailored to your unique needs. I aim to create stunning visuals and user-friendly interfaces to bring your vision to life.
            </p>

            <Row className="mb-3">
                <Col>
                    <h2 className="text-center">Our Approach</h2>
                    <p>
                        My design process is collaborative and client-focused. I begin with a comprehensive consultation to understand your goals, preferences, and target audience. I then move through stages of concept development, iterative design, and client feedback, ensuring your vision is realized every step of the way.
                    </p>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <h2 className="text-center">Service Offerings</h2>
                    <ListGroup>
                        <ListGroup.Item className="bg-light mb-2 p-3 border-start border-5 border-primary">Brand Identity and Logo Design</ListGroup.Item>
                        <ListGroup.Item className="bg-light mb-2 p-3 border-start border-5 border-primary">Website and Mobile App Design</ListGroup.Item>
                        <ListGroup.Item className="bg-light mb-2 p-3 border-start border-5 border-primary">User Interface (UI) and User Experience (UX) Design</ListGroup.Item>
                        <ListGroup.Item className="bg-light mb-2 p-3 border-start border-5 border-primary">Graphic Design for Marketing Materials</ListGroup.Item>
                        <ListGroup.Item className="bg-light mb-2 p-3 border-start border-5 border-primary">Custom Illustrations and Iconography</ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <h2 className="text-center">Pricing</h2>
                    <p>
                        I offer competitive pricing with packages tailored to your project's specific needs. Contact me for a free quote and consultation.
                    </p>
                </Col>
            </Row>

            <Row className="text-center mt-4">
                <Col>
                    <p>
                        Ready to transform your ideas into reality? contact me today to start your custom design journey with me.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default CustomDesign;

