import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

// Styled components
const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  color: #333;
  margin-bottom: 16px;
  text-align: center;
`;

const Text = styled.p`
  color: #666;
  line-height: 1.6;
  font-size: 16px;
`;
function PrivacyPolicy() {
  return (
    <Container>

       {/* SEO */}
       <Helmet>
                <title>Shawn Dev - PrivacyPolicy</title>
                <meta name="description" content="Learn about Shawn Dev journey in the web design industry. Discover how i use React and React Native to create cutting-edge website and app templates. my commitment to quality and innovation sets me apart from other website agency's you will find." />
                <meta name="keywords" content="about Shawn Dev, web design, web development, React templates, React Native, innovative web solutions, website templates, app development, digital services" />
                <meta name="author" content="Shawn Cocking" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://www.shawndev.me/PrivacyPolicy" />
            </Helmet>

      <Header>Privacy Policy</Header>
      <Text>
      Privacy Policy

      <Text>1. Introduction
         This Privacy Policy explains how Shawn dev (“we”, “us”, and “our”) collects, uses, and discloses your personal information when you visit or use my services.</Text>

         <Text>2. Information Collection
         I collect personal information that you provide to us such as name, address, contact information, passwords and security data, and payment information.</Text>

         <Text>3. Use of Information
         The information I collect is used for providing and improving our services, billing, identification and authentication, and general research.</Text>

         <Text>4. Sharing of Information
         I do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers. </Text>

         <Text>5. Security
         I are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online. </Text>

         </Text>
          </Container>
  );
}

export default PrivacyPolicy;
